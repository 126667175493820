<template>
    <breadcrumb btnText="返回列表" btnIcon="n-direction-left" @btn-click="$router.go(-1)"></breadcrumb>
    <div class="page-permission-create">
        <permission-form title="新建角色" type="role"></permission-form>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import Breadcrumb from '@/components/layout/Breadcrumb.vue';
import PermissionForm from '@/components/forms/PermissionForm.vue';

@Options({
    components: {
        Breadcrumb, PermissionForm
    }
})

export default class PermissionCreate extends Vue {}
</script>