
import { Options, Vue } from 'vue-class-component';
import Breadcrumb from '@/components/layout/Breadcrumb.vue';
import PermissionForm from '@/components/forms/PermissionForm.vue';

@Options({
    components: {
        Breadcrumb, PermissionForm
    }
})

export default class PermissionCreate extends Vue {}
